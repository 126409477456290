* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
body {
  font-family: "Righteous", cursive;
  font-family: "Rubik", sans-serif;
}
p {
  margin: 0;
}
/************************************************************************/
/*******navbar**********************************************************/
.container-navbar {
  background-color: white;
  padding: 0 6rem;
  margin: 0 auto;
  max-width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 1rem 1.4rem 1.6rem rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}
.navbar {
  height: 7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.left-side {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.logo {
  font-size: 3.2rem;
  font-weight: 400;
}
.burger-icon {
  cursor: pointer;
}
.bold {
  font-weight: bold;
}
.btn-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  padding: 0.5rem;
  background-color: #c6c2c237;
  border-radius: 10rem;
}
.btn-delivery {
  background-color: black;
  color: white;
  padding: 1rem;
  border-radius: 10rem;
}
.btn-pickup {
  padding: 1rem;
}
.search-icon {
  color: #777;
}
.input-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #c6c2c237;
  padding: 1.4rem;
  border-radius: 10rem;
  max-width: 70rem;
  width: 100%;
}
.search-food-input {
  border: none;
  background-color: transparent;
}
.search-food-input:focus {
  outline: none;
}
.cart-btn {
  background-color: black;
  color: white;
  padding: 1.2rem 1.6rem;
  border-radius: 10rem;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

/*****************mobile-menu***************************************/
.overlay {
  background-color: rgba(0, 0, 0, 0.75);
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: none;
  z-index: 4;
}
.show-overlay {
  display: block;
}
.mobile-menu {
  height: 100vh;
  max-width: 30rem;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: white;
  transition: 0.3s all ease-in;
  transform: translateX(-50rem);
  z-index: 5;
}
.show-mobile-menu {
  transform: translateX(0);
}
.close-icon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
.menu-logo {
  font-size: 2rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
}
.menu-list-container {
  position: absolute;
  top: 10rem;
  left: 5rem;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  list-style-type: none;
  font-size: 1.6rem;
}
.item-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}
/**************************************************************************************/
/*************hero*********************************************************************/
.hero {
  max-width: 140rem;
  margin: 0 auto;
  position: relative;
}
.hero-background-img {
  background-image: url("https://images.unsplash.com/photo-1609167830220-7164aa360951?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50rem;
}
.overlay-hero {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  z-index: 1;
}
.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.6rem;
  max-width: 40%;
  position: absolute;
  top: 50%;
  left: 5rem;
  transform: translateY(-50%);
  z-index: 3;
}
.hero-text {
  font-size: 6.4rem;
  font-weight: bold;
  color: white;
}
.hero-text-color {
  color: coral;
}
/***********************************************************************************/
/********cards**********************************************************************/
.cards-container {
  max-width: 140rem;
  margin: auto;
  padding: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card {
  position: relative;
  overflow: hidden;
  width: 40rem;
  height: 25rem;
  border-radius: 0.6rem;
  background-image: url("https://images.unsplash.com/photo-1618009544639-d61a46e7974d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
  padding: 0 1.6rem;
  box-shadow: 1rem 1.4rem 1.6rem rgba(0, 0, 0, 0.1);
}
.two {
  background-image: url("https://images.unsplash.com/photo-1485962398705-ef6a13c41e8f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80");
}
.three {
  background-image: url("https://images.unsplash.com/photo-1621792907526-e69888069079?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80");
}

.title-card {
  font-size: 3.2rem;
  color: white;
  position: relative;
  z-index: 2;
}
.text-card {
  font-size: 1.6rem;
  color: white;
  position: relative;
  z-index: 2;
}
.btn-card {
  border: none;
  background-color: white;
  padding: 1rem 1.4rem;
  border-radius: 0.6rem;
  margin-top: 2rem;
  cursor: pointer;
  transition: 0.3s all ease-in;
  position: relative;
  z-index: 2;
}
.btn-card:hover {
  background-color: coral;
  color: white;
}
.overlay-cards {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}
/****************************************************************************************************/
/*****************Food******************************************************************************/
.foods-container {
  max-width: 140rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
}
.title-food {
  text-align: center;
  font-size: 4.2rem;
  color: coral;
}
.filter-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1.6rem;
}
.filter-btns {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1.6rem;
}
.filter-btn {
  background-color: transparent;
  border: 1px solid coral;
  color: coral;
  border-radius: 0.8rem;
  padding: 0.8rem 1.4rem;
  cursor: pointer;
  transition: 0.3s all ease-in;
}
.filter-btn:hover {
  background-color: coral;
  color: white;
}
.filter-title {
  color: #333;
  font-size: 1.8rem;
}
/***********Food-card*******************************************************/
.foods-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem;
  padding-bottom: 3.2rem;
}
.food-card {
  background-color: rgba(228, 229, 229, 0.4);
  max-width: 50rem;
  border-radius: 0.6rem;
  overflow: hidden;
  box-shadow: 1rem 1.4rem 1.6rem rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: 0.3s all ease-in;
}
.food-card:hover {
  transform: scale(1.05);
}
.food-card-details {
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
}
.food-card-img {
  height: 30rem;
  width: 100%;
  object-fit: cover;
}
.food-card-title {
  font-size: 1.6rem;
  color: #333;
}
.food-card-price {
  font-size: 1.4rem;
  color: white;
  background-color: coral;
  padding: 0.5rem;
  border-radius: 10rem;
}
/***************************************************************************************/
/**************categories**************************************************************/
.categories-container {
  max-width: 140rem;
  margin: 3.2rem auto;
}
.cards-container-categories {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 3.2rem;
  margin-top: 3.2rem;
}
.categories-title {
  color: coral;
  font-size: 4.2rem;
  text-align: center;
}
.card-category {
  background-color: rgba(226, 228, 228, 0.432);
  border-radius: 0.6rem;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 1rem 1.4rem 1.6rem rgba(0, 0, 0, 0.1);
}
.title-cat {
  font-size: 2rem;
}
/*********************************************************************************************/
/************footer***************************************************************************/
.footer {
  height: 8.5rem;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-text {
  color: white;
  font-size: 1.6rem;
}
/**************************************************************************************/
/**********responsive******************************************************************/
@media (max-width: 1400px) {
  html {
    font-size: 60%;
  }
  .hero {
    width: 90%;
  }
  .cards-container {
    width: 90%;
    gap: 2rem;
  }
  .foods-container {
    width: 90%;
  }
  .categories-container {
    width: 90%;
  }
  .btn-nav {
    display: none;
  }
}

@media (max-width: 1080px) {
  .cart-btn {
    display: none;
  }
  .logo {
    font-size: 2.8rem;
  }
  .hero-text {
    font-size: 5rem;
  }
  .title-card {
    font-size: 2.6rem;
  }
  .container-navbar {
    padding: 0 4rem;
  }
  .btn-nav {
    display: none;
  }
  .input-container {
    width: 85%;
  }
  .foods-card-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .cards-container-categories {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 940px) {
  .logo {
    font-size: 2.4rem;
  }
  .hero-text {
    font-size: 4.2rem;
  }
  .title-card {
    font-size: 2.4rem;
  }
  .input-container {
    width: 80%;
  }
}
@media (max-width: 870px) {
  .hero-text {
    font-size: 3.6rem;
  }
  .title-card {
    font-size: 2.4rem;
  }
  .logo {
    font-size: 1.6rem;
    font-weight: 400;
  }
  .burger-icon {
    height: 2rem;
    width: 2rem;
  }
  .input-container {
    padding: 1rem;
  }
  .food-card-img {
    height: 25rem;
  }
}

@media (max-width: 650px) {
  .input-container {
    width: 70%;
  }
}

@media (max-width: 550px) {
  .hero-text {
    font-size: 2.7rem;
  }
  .food-card-img {
    height: 20rem;
  }
  .foods-card-container {
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
    column-gap: 1rem;
  }
  .cards-container-categories {
    grid-template-columns: 1fr 1fr;
  }
  .cards-container {
    flex-direction: column;
  }
  .card {
    width: 100%;
  }
  .title-cat {
    font-size: 1.4rem;
  }
  .img-cat {
    height: 8rem;
    width: 8rem;
  }
  .card-category {
    padding: 2rem;
  }
}
@media (max-width: 415px) {
  .hero-text {
    font-size: 1.8rem;
  }
  .food-card-img {
    height: 15rem;
  }
  .food-card-title {
    font-size: 1.3rem;
  }
  .food-card-price {
    font-size: 1.2rem;
  }
  .filter-btn {
    font-size: 1.4rem;
  }
  .title-cat {
    font-size: 1.4rem;
  }
  .img-cat {
    height: 5rem;
    width: 5rem;
  }
  .card-category {
    padding: 1rem;
  }
  .btn-card {
    font-size: 1.2rem;
  }
  .input-container {
    width: 60%;
  }
  .search-food-input {
    font-size: 1.4rem;
  }
  .title-food {
    font-size: 3.2rem;
  }
  .categories-title {
    font-size: 3.2rem;
  }
}
@media (max-width: 395px) {
  .search-icon {
    display: none;
  }
}
@media (max-width: 365px) {
  .title-food {
    font-size: 2.8rem;
  }
  .categories-title {
    font-size: 2.8rem;
  }
}

@media (max-width: 320px) {
  html {
    font-size: 35%;
  }
  .mobile-menu {
    width: 80%;
  }
  .input-container {
    width: 50%;
  }
  .icon-menu-list {
    height: 2rem;
    width: 2rem;
  }
  .close-icon {
    height: 2rem;
    width: 2rem;
  }
}
